// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-omnicept-js": () => import("./../../../src/pages/omnicept.js" /* webpackChunkName: "component---src-pages-omnicept-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-trial-organization-js": () => import("./../../../src/pages/trial/organization.js" /* webpackChunkName: "component---src-pages-trial-organization-js" */),
  "component---src-pages-version-js": () => import("./../../../src/pages/version.js" /* webpackChunkName: "component---src-pages-version-js" */),
  "component---src-pages-vive-pro-eye-js": () => import("./../../../src/pages/vive-pro-eye.js" /* webpackChunkName: "component---src-pages-vive-pro-eye-js" */)
}

